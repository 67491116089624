import React from 'react'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'
import "./../component/style.css";
import "./About.css"
import abtimg from "./../images/aboutpc.jpg";
const About = () => {
  return (
    <div class="page">
      <Navbar />

      <div id="colorlib-page">
        <section class="hero-wrap imgh1">
          <div class="overlay"></div>
          <div class="container">
            <div
              class="row no-gutters text align-items-end justify-content-center"
              data-scrollax-parent="true"
            >
              <div class="col-md-8 ftco-animate text-center">
                <p class="breadcrumbs">
                  <span class="mr-2">
                    <a href="/">Home</a>
                  </span>{" "}
                  <span>About</span>
                </p>
                <h1 class="mb-5 bread">About Us</h1>
              </div>
            </div>
          </div>
        </section>

        <section class="ftco-section ftco-no-pt ftco-no-pb ftco-about-section">
          <div class="container-fluid px-0">
            <div class="row d-md-flex text-wrapper">
              <div class="one-half img ">
                <img src={abtimg} alt="" />
              </div>
              <div class="one-half half-text d-flex justify-content-end align-items-center">
                <div class="text-inner pl-md-5">
                  <span class="subheading">Hello!</span>
                  <h3 class="heading">Tihut Cultural Design</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Distinctio, fugit cumque aut voluptatem reprehenderit ab
                    commodi? Sed explicabo, veritatis, magni id atque quisquam
                    veniam suscipit ducimus doloribus sapiente, omnis aperiam!
                  </p>
                  <ul class="my-4">
                    <li>
                      <span class="ion-ios-checkmark-circle mr-2"></span> Even
                      the all-powerful Pointing
                    </li>
                    <li>
                      <span class="ion-ios-checkmark-circle mr-2"></span> Behind
                      the word mountains
                    </li>
                    <li>
                      <span class="ion-ios-checkmark-circle mr-2"></span>{" "}
                      Separated they live in Bookmarksgrove
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="ftco-section bg-dark">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <div class="services ftco-animate text-center">
                  <div class="icon d-flex justify-content-center align-items-center">
                    <span class="flaticon-quality"></span>
                  </div>
                  <div class="info mt-4">
                    <h3 class="mb-4">Fashion Shows</h3>
                    <p>
                      Even the all-powerful Pointing has no control about the
                      blind texts
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services ftco-animate text-center">
                  <div class="icon d-flex justify-content-center align-items-center">
                    <span class="flaticon-megaphone"></span>
                  </div>
                  <div class="info mt-4">
                    <h3 class="mb-4">Corporate Events</h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services ftco-animate text-center">
                  <div class="icon d-flex justify-content-center align-items-center">
                    <span class="flaticon-photo-camera"></span>
                  </div>
                  <div class="info mt-4">
                    <h3 class="mb-4">Commercial Photo Shots</h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="services ftco-animate text-center">
                  <div class="icon d-flex justify-content-center align-items-center">
                    <span class="flaticon-shopping-bag"></span>
                  </div>
                  <div class="info mt-4">
                    <h3 class="mb-4">Exhibitions/Trade Shows Shows</h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="ftco-section testimony-section img imgh2">
          <div class="overlay"></div>
          <div class="container">
            <div class="row d-md-flex justify-content-center">
              <div class="col-md-8 ftco-animate">
                <div class="carousel-testimony owl-carousel">
                  <div class="item">
                    <div class="testimony-wrap text-center">
                      <div class="user-img mb-5 imgp1">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                      </div>
                      <div class="text">
                        <p class="mb-5">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Temporibus, similique! Numquam reprehenderit in
                          vero earum autem dolorum tempora? Possimus officiis
                          veniam iste, cum necessitatibus ipsum numquam
                          doloribus beatae rerum perspiciatis!
                        </p>
                        <p class="name">Mike Lewis</p>
                        <span class="position">Architect</span>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="testimony-wrap text-center">
                      <div class="user-img mb-5 imgp2">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                      </div>
                      <div class="text">
                        <p class="mb-5">
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Velit neque, similique nulla enim esse dolorum
                          voluptatibus totam voluptates suscipit? Suscipit harum
                          vel voluptatibus, eveniet eaque mollitia temporibus
                          libero alias ab.
                        </p>
                        <p class="name">Dennis Green</p>
                        <span class="position">Architect</span>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="testimony-wrap text-center">
                      <div class="user-img mb-5 imgp3">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                      </div>
                      <div class="text">
                        <p class="mb-5">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Fuga pariatur, praesentium odit, atque expedita,
                          aliquid voluptate totam aperiam doloremque eum
                          recusandae! Delectus temporibus saepe facere
                          praesentium quas quae impedit placeat?
                        </p>
                        <p class="name">Dennis Green</p>
                        <span class="position">Architect</span>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="testimony-wrap text-center">
                      <div class="user-img mb-5 imgp3">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                      </div>
                      <div class="text">
                        <p class="mb-5">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Aspernatur, repellendus perspiciatis. Aliquid ea
                          pariatur unde, delectus possimus harum numquam nostrum
                          sequi sint. Veniam voluptatem modi amet hic laboriosam
                          ex numquam?
                        </p>
                        <p class="name">Dennis Green</p>
                        <span class="position">Customer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="ftco-appointment ftco-section">
          <div class="overlay"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 mb-5 heading-section text-center ftco-animate">
                <span class="subheading">Tihut Design</span>
                <h2 class="mb-4">Contact Us</h2>
                <p class="mb-5"> Please feel free to contact us</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <p>
                      <span>Address:</span> Ethiopia,South Central
                      Region,Arbaminch City
                    </p>
                  </div>
                  <div class="col-md-12 mb-3">
                    <p>
                      <span>Phone:</span>{" "}
                      <a href="tel://0966438359">0966438359</a>
                    </p>
                  </div>
                  <div class="col-md-12 mb-3">
                    <p>
                      <span>Email:</span>{" "}
                      <a href="mailto:info@yoursite.com">firaolteg46@gmail.com</a>
                    </p>
                  </div>
                  <div class="col-md-12 mb-3">
                    <p>
                      <span>Whatsapp:</span> <a href="#">0966438359</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-8 appointment ftco-animate">
                <form action="#" class="appointment-form">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="d-md-flex">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-me-flex">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-me-flex">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-me-flex">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Your City"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          class="form-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="submit"
                          value="Send A Message"
                          class="btn btn-primary py-3 px-4"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>

      {/* <!-- Modal --> */}
      {/* <div
        class="modal fade"
        id="modalRequest"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalRequestLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalRequestLabel">
                Request a Quote
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form action="#">
                <div class="form-group">
                  <label for="appointment_name" class="text-black">
                    Full Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="appointment_name"
                  />
                </div>
                <div class="form-group">
                  <label for="appointment_email" class="text-black">
                    Email
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="appointment_email"
                  />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="appointment_date" class="text-black">
                        Date
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="appointment_date"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="appointment_time" class="text-black">
                        Time
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="appointment_time"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="appointment_message" class="text-black">
                    Message
                  </label>
                  <textarea
                    name=""
                    id="appointment_message"
                    class="form-control"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    class="btn btn-primary"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default About
