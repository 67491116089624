import React from 'react'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'
import "./../component/style.css";
import "./Contact.css"
const Contact = () => {
  return (
    <div class="page">
      <Navbar />

      <div id="colorlib-page">
        <section class="hero-wrap imgh1">
          <div class="overlay"></div>
          <div class="container">
            <div
              class="row no-gutters text align-items-end justify-content-center"
              data-scrollax-parent="true"
            >
              <div class="col-md-8 ftco-animate text-center">
                <p class="breadcrumbs">
                  <span class="mr-2">
                    <a href="/">Home</a>
                  </span>{" "}
                  <span>Contact</span>
                </p>
                <h1 class="mb-5 bread">Contact Us</h1>
              </div>
            </div>
          </div>
        </section>

        <section class="ftco-section contact-section">
          <div class="container mt-5">
            <div class="row block-9">
              <div class="col-md-4 order-last contact-info ftco-animate">
                <div class="row">
                  <div class="col-md-12 mb-4">
                    <h2>Contact Information</h2>
                  </div>
                  <div class="col-md-12 mb-3">
                    <p>
                      <span>Address:</span> Ethiopia,South Central
                      Region,Arbaminch City
                    </p>
                  </div>
                  <div class="col-md-12 mb-3">
                    <p>
                      <span>Phone:</span>{" "}
                      <a href="tel://1234567920">+ 1235 2355 98</a>
                    </p>
                  </div>
                  <div class="col-md-12 mb-3">
                    <p>
                      <span>Email:</span>{" "}
                      <a href="mailto:info@yoursite.com">firaolteg46@gmail.com</a>
                    </p>
                  </div>
                  <div class="col-md-12 mb-3">
                    <p>
                      <span>Whatsapp:</span> <a href="#">0966438359</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-6 order-first ftco-animate">
                <form action="#">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Email"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Subject"
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="7"
                      class="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <input
                      type="submit"
                      value="Send Message"
                      class="btn btn-primary py-3 px-5"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="modalRequest"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalRequestLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalRequestLabel">
                Request a Quote
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form action="#">
                <div class="form-group">
                  <label for="appointment_name" class="text-black">
                    Full Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="appointment_name"
                  />
                </div>
                <div class="form-group">
                  <label for="appointment_email" class="text-black">
                    Email
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="appointment_email"
                  />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="appointment_date" class="text-black">
                        Date
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="appointment_date"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="appointment_time" class="text-black">
                        Time
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="appointment_time"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="appointment_message" class="text-black">
                    Message
                  </label>
                  <textarea
                    name=""
                    id="appointment_message"
                    class="form-control"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    class="btn btn-primary"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact
