import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Designs from "./pages/Designs";
import Home from "./pages/Home";
function App() {
  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/blog",
          element: <Blog />,
        },
        {
          path: "/designs",
          element: <Designs />,
        },
        {
          path: "/",
          element: <Home />,
        },
      ])}
    />
  );
}

export default App;
