import React from 'react'
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';
import "./../component/style.css";
import "./Home.css"
import img1 from "./../images/design1.jpg";
import img2 from "./../images/design2.jpg";
import img3 from "./../images/design3.jpg";
import img4 from "./../images/design4.jpg";
import img5 from "./../images/design5.jpg";
import img6 from "./../images/design6.jpg";
import img7 from "./../images/design7.jpg";
import abtimg from "./../images/aboutpc.jpg";
import tht from "./../images/tht.jpg";
const Home = () => {
  return (
    <div>
      <div class="page">
        <Navbar />
        <div id="colorlib-page">
          <section id="home" class="video-hero js-fullheight h-[700px] imgh2">
            <div class="overlay"></div>
            <a
              class="player "
              data-property="{videoURL:'https://www.youtube.com/watch?v=ITUid-bDsl8',containment:'#home', showControls:false, autoPlay:true, loop:true, mute:true, startAt:0, opacity:1, quality:'default'}"
            ></a>
            {/* <video src={bgvideo} class="player " width="" height="" controls>
              
            </video> */}
            <div class="container">
              <div class="row js-fullheight justify-content-center d-flex align-items-center">
                <div class="col-md-8">
                  <div class="text text-center">
                    <span class="subheading">Welcome to</span>
                    <h2>Tihut</h2>
                    <h3 class="mb-4">Cultural Design</h3>
                    <p>
                      <a
                        href="/contact"
                        class="btn btn-primary py-3 px-4 text-black"
                      >
                        Contact Us
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section class="d-sm-hidden d-md-flex ftco-section ftco-no-pt ftco-featured-model">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6 col-lg-3">
                  <div class="model-entry">
                    <div class="model-img imgh3"></div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-3">
                  <div class="model-entry">
                    <div class="model-img imgh4"></div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-3">
                  <div class="model-entry">
                    <div class="model-img imgh5"></div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-3">
                  <div class="model-entry">
                    <div class="model-img imgh6"></div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section class="ftco-section ftco-no-pt ftco-no-pb ftco-about-section">
            <div class="container-fluid px-0">
              <div class="row d-md-flex text-wrapper">
                <div class="one-half img">
                  <img src={abtimg} alt="" />
                </div>
                <div class="one-half half-text d-flex justify-content-end align-items-center ">
                  <div class="text-inner pl-md-5">
                    <span class="subheading">Hello!</span>
                    <h3 class="heading">Tihut Cultural Design</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Facilis ratione consequatur similique earum mollitia omnis
                      eum aperiam soluta quidem atque officiis perspiciatis
                      molestiae maxime, aliquid autem laborum optio nemo dolor?
                    </p>
                    <ul class="my-4">
                      <li>
                        <span class="ion-ios-checkmark-circle mr-2"></span> Even
                        the all-powerful Pointing
                      </li>
                      <li>
                        <span class="ion-ios-checkmark-circle mr-2"></span>{" "}
                        Behind the word mountains
                      </li>
                      <li>
                        <span class="ion-ios-checkmark-circle mr-2"></span>{" "}
                        Separated they live in Bookmarksgrove
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="ftco-section bg-dark text-white">
            <div class="container">
              <div class="row">
                <div class="col-md-3">
                  <div class="services ftco-animate text-center">
                    <div class="icon flex justify-center align-items-center">
                      <span class="flaticon-quality"></span>
                    </div>
                    <div class="info mt-4">
                      <h3 class="mb-4">Fashion Shows</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="services ftco-animate text-center">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <span class="flaticon-megaphone"></span>
                    </div>
                    <div class="info mt-4">
                      <h3 class="mb-4">Corporate Events</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="services ftco-animate text-center">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <span class="flaticon-photo-camera"></span>
                    </div>
                    <div class="info mt-4">
                      <h3 class="mb-4">Commercial Photo Shots</h3>
                      <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="services ftco-animate text-center">
                    <div class="icon d-flex justify-content-center align-items-center">
                      <span class="flaticon-shopping-bag"></span>
                    </div>
                    <div class="info mt-4">
                      <h3 class="mb-4">Exhibitions/Trade Shows Shows</h3>
                      <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="ftco-section ftco-no-pt ftco-no-pb">
            <div class="container-fluid px-4">
              <div class="row d-flex">
                <div class="col-md-6 col-lg-3 d-flex align-items-center ftco-animate">
                  <div class="heading-section text-center">
                    <h2 class="">Our Tops Design's</h2>
                    <p></p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img imgh8">
                      <img src={img1} alt="" />
                      <div class="name text-center">
                        <h3>{/* <a href="model-single.html">Andrea</a> */}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img">
                      <img src={img2} alt="" />
                      <div class="name text-center">
                        <h3>{/* <a href="model-single.html">Cassy</a> */}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img ">
                      <img src={img3} alt="" />
                      <div class="name text-center">
                        <h3>{/* <a href="model-single.html">Angela</a> */}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img ">
                      <img src={img4} alt="" />
                      <div class="name text-center">
                        <h3>{/* <a href="model-single.html">May</a> */}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img ">
                      <img src={img5} alt="" />
                      <div class="name text-center">
                        <h3>{/* <a href="model-single.html">Nicole</a> */}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img ">
                      <img src={img6} alt="" />
                      <div class="name text-center">
                        <h3>{/* <a href="model-single.html">Jannah</a> */}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img imgh14">
                      <div class="name text-center">
                        <h3>
                          <a href="model-single.html">Mariel</a>
                        </h3>
                      </div>
                      <div class="text text-center">
                        <h3>
                          <a href="model-single.html">
                            Mariel
                            <br />
                            <span>Rivera</span>
                          </a>
                        </h3>
                        <div class="d-flex models-info">
                          <div class="box">
                            <p>Height</p>
                            <span>185</span>
                          </div>
                          <div class="box">
                            <p>Bust</p>
                            <span>79</span>
                          </div>
                          <div class="box">
                            <p>Waist</p>
                            <span>40</span>
                          </div>
                          <div class="box">
                            <p>Hips</p>
                            <span>87</span>
                          </div>
                          <div class="box">
                            <p>Shoe</p>
                            <span>40</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img imgh15">
                      <div class="name text-center">
                        <h3>
                          <a href="model-single.html">Maria</a>
                        </h3>
                      </div>
                      <div class="text text-center">
                        <h3>
                          <a href="model-single.html">
                            Maria
                            <br />
                            <span>Corazon</span>
                          </a>
                        </h3>
                        <div class="d-flex models-info">
                          <div class="box">
                            <p>Height</p>
                            <span>185</span>
                          </div>
                          <div class="box">
                            <p>Bust</p>
                            <span>79</span>
                          </div>
                          <div class="box">
                            <p>Waist</p>
                            <span>40</span>
                          </div>
                          <div class="box">
                            <p>Hips</p>
                            <span>87</span>
                          </div>
                          <div class="box">
                            <p>Shoe</p>
                            <span>40</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img imgh16">
                      <div class="name text-center">
                        <h3>
                          <a href="model-single.html">Ozawa</a>
                        </h3>
                      </div>
                      <div class="text text-center">
                        <h3>
                          <a href="model-single.html">
                            Ozawa
                            <br />
                            <span>Smith</span>
                          </a>
                        </h3>
                        <div class="d-flex models-info">
                          <div class="box">
                            <p>Height</p>
                            <span>185</span>
                          </div>
                          <div class="box">
                            <p>Bust</p>
                            <span>79</span>
                          </div>
                          <div class="box">
                            <p>Waist</p>
                            <span>40</span>
                          </div>
                          <div class="box">
                            <p>Hips</p>
                            <span>87</span>
                          </div>
                          <div class="box">
                            <p>Shoe</p>
                            <span>40</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div class="col-md-6 col-lg-3 ftco-animate">
                  <div class="model-entry">
                    <div class="model-img imgh17">
                      <div class="name text-center">
                        <h3>
                          <a href="model-single.html">Maine</a>
                        </h3>
                      </div>
                      <div class="text text-center">
                        <h3>
                          <a href="model-single.html">
                            Maine
                            <br />
                            <span>Mendoza</span>
                          </a>
                        </h3>
                        <div class="d-flex models-info">
                          <div class="box">
                            <p>Height</p>
                            <span>185</span>
                          </div>
                          <div class="box">
                            <p>Bust</p>
                            <span>79</span>
                          </div>
                          <div class="box">
                            <p>Waist</p>
                            <span>40</span>
                          </div>
                          <div class="box">
                            <p>Hips</p>
                            <span>87</span>
                          </div>
                          <div class="box">
                            <p>Shoe</p>
                            <span>40</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-md-6 col-lg-3 d-flex justify-content-center align-items-center ftco-animate">
                  <div class="btn-view">
                    <p>
                      <a href="/designs" class="btn btn-white py-3 px-5">
                        View more
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="ftco-section">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-8 mb-5 heading-section text-center ftco-animate">
                  <span class="subheading">Blog</span>
                  <h2 class="mb-4">Recent Blog</h2>
                  <p class="mb-5">
                    Ethiopia,Arbaminch city,Gimash infront of Arbaminch referral
                    Hospital
                  </p>
                </div>
              </div>
              <div class="row d-flex">
                <div class="col-md-4 d-flex ftco-animate">
                  <div class="blog-entry bg-dark align-self-stretch">
                    <a href="blog-single.html" class="block-20 ">
                      <img src={img7} alt="" />
                    </a>
                    <div class="text p-4 d-block">
                      <div class="meta mb-3">
                        <div>
                          <a href="#">10 17, 2024</a>
                        </div>
                      </div>
                      <h3 class="heading mt-3">
                        <a href="#">I met Artist Asge Dendasho</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 d-flex ftco-animate">
                  <div class="blog-entry bg-dark align-self-stretch">
                    <a href="blog-single.html" class="block-20">
                      <img src={abtimg} alt="" />
                    </a>
                    <div class="text p-4 d-block">
                      <div class="meta mb-3">
                        <div>
                          <a href="#">10 16, 2019</a>
                        </div>
                      </div>
                      <h3 class="heading mt-3">
                        <a href="#">asdgfg dfasdfg rtyqqyeqt zffsdfghf</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 d-flex ftco-animate">
                  <div class="blog-entry bg-dark align-self-stretch">
                    <a href="blog-single.html" class="block-20">
                      {" "}
                      <img src={tht} alt="" />
                    </a>
                    {/* <img src={tht} alt="" /> */}
                    <div class="text p-4 d-block">
                      <div class="meta mb-3">
                        <div>
                          <a href="#">10, 15, 2024</a>
                        </div>
                      </div>
                      <h3 class="heading mt-3">
                        <a href="#">feffgfg g hfghgb sjfjg</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="ftco-section testimony-section img imgh21">
            <div class="overlay"></div>
            <div class="container">
              <div class="row d-md-flex justify-content-center">
                <div class="col-md-8 ftco-animate">
                  <div class="carousel-testimony owl-carousel">
                    <div class="item">
                      <div class="testimony-wrap text-center">
                        <div class="user-img mb-5 imgh22">
                          <span class="quote d-flex align-items-center justify-content-center">
                            <i class="icon-quote-left"></i>
                          </span>
                        </div>
                        <div class="text">
                          <p class="mb-5">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Modi rem ab porro maiores possimus quam veniam
                            nam, architecto laudantium magnam aut est quisquam
                            ipsum necessitatibus dignissimos tempora pariatur
                            natus officiis.
                          </p>
                          <p class="name">Mike Lewis</p>
                          <span class="position">Architect</span>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="testimony-wrap text-center">
                        <div class="user-img mb-5 imgh23">
                          <span class="quote d-flex align-items-center justify-content-center">
                            <i class="icon-quote-left"></i>
                          </span>
                        </div>
                        <div class="text">
                          <p class="mb-5">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Repudiandae ea vel debitis enim eaque eum,
                            asperiores quia quas placeat itaque doloremque est
                            dolore rerum alias voluptatum sint distinctio
                            adipisci facere?
                          </p>
                          <p class="name">Dennis Green</p>
                          <span class="position">Architect</span>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="testimony-wrap text-center">
                        <div class="user-img mb-5 imgh24">
                          <span class="quote d-flex align-items-center justify-content-center">
                            <i class="icon-quote-left"></i>
                          </span>
                        </div>
                        <div class="text">
                          <p class="mb-5">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Deleniti ab ratione cupiditate odit excepturi,
                            consequatur accusamus debitis, fugit velit, deserunt
                            quisquam tenetur laboriosam in veniam numquam
                            exercitationem qui doloribus aliquid.
                          </p>
                          <p class="name">Dennis Green</p>
                          <span class="position">Architect</span>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div class="testimony-wrap text-center">
                        <div class="user-img mb-5 imgh25">
                          <span class="quote d-flex align-items-center justify-content-center">
                            <i class="icon-quote-left"></i>
                          </span>
                        </div>
                        <div class="text">
                          <p class="mb-5">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Modi amet dicta tempore voluptate sed dolorum,
                            eos facilis omnis vero vitae ducimus accusamus
                            tenetur dolore sequi suscipit repudiandae, minima,
                            nulla repellat?
                          </p>
                          <p class="name">Dennis Green</p>
                          <span class="position">Customer</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="ftco-appointment ftco-section">
            <div class="overlay"></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-8 mb-5 heading-section text-center ftco-animate">
                  <span class="subheading">Tihut Design</span>
                  <h2 class="mb-4">Contact Us</h2>
                  <p class="mb-5">
                    Ethiopia,Arbaminch city,Gimash infront of Arbaminch referral
                    Hospital
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <p>
                        <span>Address:</span> Ethiopia,Arbaminch city,Gimash
                        infront of Arbaminch referral Hospital
                      </p>
                    </div>
                    <div class="col-md-12 mb-3">
                      <p>
                        <span>Phone:</span>{" "}
                        <a href="tel://0966438359">0966438359</a>
                      </p>
                    </div>
                    <div class="col-md-12 mb-3">
                      <p>
                        <span>Email:</span>{" "}
                        <a href="mailto:info@yoursite.com">
                          firaolteg46@gmail.com
                        </a>
                      </p>
                    </div>
                    <div class="col-md-12 mb-3">
                      <p>
                        <span>Whatsapp:</span> <a href="#">0966438359</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 appointment ftco-animate">
                  <form action="#" class="appointment-form">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="d-md-flex">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="d-me-flex">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="d-me-flex">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="d-me-flex">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Your City"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            class="form-control"
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input
                            type="submit"
                            value="Send A Message"
                            class="btn btn-primary py-3 px-4"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <Footer />
          {/* 
      <!-- loader --> */}
          {/* <div id="ftco-loader" class="show fullscreen">
            <svg class="circular" width="48px" height="48px">
              <circle
                class="path-bg"
                cx="24"
                cy="24"
                r="22"
                fill="none"
                stroke-width="4"
                stroke="#eeeeee"
              />
              <circle
                class="path"
                cx="24"
                cy="24"
                r="22"
                fill="none"
                stroke-width="4"
                stroke-miterlimit="10"
                stroke="#F96D00"
              />
            </svg>
          </div> */}
        </div>
        {/* 
	    <!-- Modal --> */}
        <div
          class="modal fade"
          id="modalRequest"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalRequestLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalRequestLabel">
                  Request a Quote
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form action="#">
                  <div class="form-group">
                    <label for="appointment_name" class="text-black">
                      Full Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="appointment_name"
                    />
                  </div>
                  <div class="form-group">
                    <label for="appointment_email" class="text-black">
                      Email
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="appointment_email"
                    />
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="appointment_date" class="text-black">
                          Date
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="appointment_date"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="appointment_time" class="text-black">
                          Time
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="appointment_time"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="appointment_message" class="text-black">
                      Message
                    </label>
                    <textarea
                      name=""
                      id="appointment_message"
                      class="form-control"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <input
                      type="submit"
                      value="Send Message"
                      class="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home
