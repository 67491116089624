import React from 'react'
import img1 from "./../images/design1.jpg";
import img2 from "./../images/design2.jpg";
import "./style.css";
const Footer = () => {
  return (
    <div>
      <footer class="ftco-footer ftco-section img text-black ">
        <div class="overlay"></div>
        <div class="container">
          <div class="row mb-5">
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2 logo">
                  <a href="/">Tihut design</a>
                </h2>
                <p>
                  Ethiopia,Arbaminch city,Gimash infront of Arbaminch referral
                  Hospital
                </p>
                <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                  <li class="ftco-animate">
                    <a href="#">
                      <span class="icon-twitter"></span>
                    </a>
                  </li>
                  <li class="ftco-animate">
                    <a href="#">
                      <span class="icon-facebook"></span>
                    </a>
                  </li>
                  <li class="ftco-animate">
                    <a href="#">
                      <span class="icon-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="col-md-4 ">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2">Recent Blog</h2>
                <div class="block-21 mb-4 d-flex">
                  <a class="blog-img mr-4 ">
                    <img src={img1} alt="" />
                  </a>
                  <div class="text">
                    <h3 class="heading">
                      <a href="#">
                        Even the all-powerful Pointing has no control about
                      </a>
                    </h3>
                    <div class="meta">
                      <div>
                        <a href="#">
                          <span class="icon-calendar"></span> July 12, 2018
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block-21 mb-4 d-flex">
                  <a class="blog-img mr-4">
                    <img src={img2} alt="" />
                  </a>
                  <div class="text">
                    <h3 class="heading">
                      <a href="#">
                        Even the all-powerful Pointing has no control about
                      </a>
                    </h3>
                    <div class="meta">
                      <div>
                        <a href="#">
                          <span class="icon-calendar"></span> 10, 17, 2024
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="col-md-3">
              <div class="ftco-footer-widget mb-4 ml-md-4">
                <h2 class="ftco-heading-2">Site Links</h2>
                <ul class="list-unstyled">
                  <li>
                    <a href="/" class="py-2 d-block">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/about" class="py-2 d-block">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="/designs" class="py-2 d-block">
                      Designs
                    </a>
                  </li>
                  <li>
                    <a href="/contact" class="py-2 d-block">
                      Contact
                    </a>
                  </li>
                  <li>
                    <a href="/blog" class="py-2 d-block">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2">Have a Questions?</h2>
                <div class="block-23 mb-3">
                  <ul>
                    <li>
                      <span class="icon icon-map-marker"></span>
                      <span class="text">
                        Ethiopia,South Central Region,Arbaminch City
                      </span>
                    </li>
                    <li>
                      <a href="#">
                        <span class="icon icon-phone"></span>
                        <span class="text">0966438359</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span class="icon icon-envelope"></span>
                        <span class="text">firaolteg46@gmail.com</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <p>
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script>
                <a href="/" target="_blank">
                  Developed by Wara Technology
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer
