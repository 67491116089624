import React from 'react'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'
import "./../component/style.css";
import "./Designs.css"
import img1 from "./../images/design1.jpg";
import img2 from "./../images/design2.jpg";
import img3 from "./../images/design3.jpg";
import img4 from "./../images/design4.jpg";
import img5 from "./../images/design5.jpg";
import img6 from "./../images/design6.jpg";
import img7 from "./../images/design7.jpg";
import img8 from "./../images/design8.jpg";

const Designs = () => {
  return (
     <div class="page">
   
    <Navbar/>
    <div id="colorlib-page">
			
			<section class="hero-wrap imgh1" >
      	<div class="overlay"></div>
	      <div class="container">
	        <div class="row no-gutters text align-items-end justify-content-center" data-scrollax-parent="true">
	          <div class="col-md-8 ftco-animate text-center">
	            <p class="breadcrumbs"><span class="mr-2"><a href="/">Home</a></span> <span>Designs</span></p>
	            <h1 class="mb-5 bread">Our Top Designs</h1>
	          </div>
	        </div>
	      </div>
      </section>
			
			<section class="ftco-section  ftco-no-pb">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-md-10 ftco-animate">
	    				<div class="heading-section text-center mb-5">
	    					<span class="subheading">Designs</span>
		    				<h2 class="">Our Tops Designs</h2>
	              {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum quia similique rem sint expedita autem sed ratione aliquid molestiae voluptatum nesciunt, exercitationem excepturi voluptate ullam qui, blanditiis mollitia, dignissimos iure. </p> */}
              </div>
	    			</div>
					</div>
				</div>
	    	<div class="container-fluid px-4">
	    		<div class="row d-flex">
	    			<div class="col-md-6 col-lg-3 ftco-animate">
	    				<div class="model-entry">
			    			<div class="model-img" >
								<img src={img1} alt=""/>
			    				{/* <div class="name text-center">
			    					<h3><a href="model-single.html">Andrea</a></h3>
			    				</div> */}
			    				{/* <div class="text text-center">
			    					<h3><a href="model-single.html">Andrea<br/><span>Smith</span></a></h3>
			    					<div class="d-flex models-info">
			    						<div class="box">
		                		<p>Height</p>
		                		<span>185</span>
		                	</div>
		                	<div class="box">
		                		<p>Bust</p>
		                		<span>79</span>
		                	</div>
		                	<div class="box">
		                		<p>Waist</p>
		                		<span>40</span>
		                	</div>
		                	<div class="box">
		                		<p>Hips</p>
		                		<span>87</span>
		                	</div>
		                	<div class="box">
		                		<p>Shoe</p>
		                		<span>40</span>
		                	</div>
			    					</div>
			    				</div> */}
			    			</div>
		    			</div>
		    		</div>
		    		<div class="col-md-6 col-lg-3 ftco-animate">
		    			<div class="model-entry">
			    			<div class="model-img">
							<img src={img2} alt=""/>
			    				{/* <div class="name text-center">
			    					<h3><a href="model-single.html">Cassy</a></h3>
			    				</div> */}
			    				{/* <div class="text text-center">
			    					<h3><a href="model-single.html">Cassy<br/><span>Tailor</span></a></h3>
			    					<div class="d-flex models-info">
			    						<div class="box">
		                		<p>Height</p>
		                		<span>185</span>
		                	</div>
		                	<div class="box">
		                		<p>Bust</p>
		                		<span>79</span>
		                	</div>
		                	<div class="box">
		                		<p>Waist</p>
		                		<span>40</span>
		                	</div>
		                	<div class="box">
		                		<p>Hips</p>
		                		<span>87</span>
		                	</div>
		                	<div class="box">
		                		<p>Shoe</p>
		                		<span>40</span>
		                	</div>
			    					</div>
			    				</div> */}
			    			</div>
			    		</div>
	    			</div>
	    			<div class="col-md-6 col-lg-3 ftco-animate">
	    				<div class="model-entry">
			    			<div class="model-img" >
							<img src={img3} alt=""/>
			    				{/* <div class="name text-center">
			    					<h3><a href="model-single.html">Angela</a></h3>
			    				</div> */}
			    				{/* <div class="text text-center">
			    					<h3><a href="model-single.html">Angela<br/><span>Bocks</span></a></h3>
			    					<div class="d-flex models-info">
			    						<div class="box">
		                		<p>Height</p>
		                		<span>185</span>
		                	</div>
		                	<div class="box">
		                		<p>Bust</p>
		                		<span>79</span>
		                	</div>
		                	<div class="box">
		                		<p>Waist</p>
		                		<span>40</span>
		                	</div>
		                	<div class="box">
		                		<p>Hips</p>
		                		<span>87</span>
		                	</div>
		                	<div class="box">
		                		<p>Shoe</p>
		                		<span>40</span>
		                	</div>
			    					</div>
			    				</div> */}
			    			</div>
			    		</div>
	    			</div>
	    			<div class="col-md-6 col-lg-3 ftco-animate">
	    				<div class="model-entry">
			    			<div class="model-img img4">
							<img src={img4} alt=""/>
			    				{/* <div class="name text-center">
			    					<h3><a href="model-single.html">May</a></h3>
			    				</div> */}
			    				{/* <div class="text text-center">
			    					<h3><a href="model-single.html">May<br/><span>Doe</span></a></h3>
			    					<div class="d-flex models-info">
			    						<div class="box">
		                		<p>Height</p>
		                		<span>185</span>
		                	</div>
		                	<div class="box">
		                		<p>Bust</p>
		                		<span>79</span>
		                	</div>
		                	<div class="box">
		                		<p>Waist</p>
		                		<span>40</span>
		                	</div>
		                	<div class="box">
		                		<p>Hips</p>
		                		<span>87</span>
		                	</div>
		                	<div class="box">
		                		<p>Shoe</p>
		                		<span>40</span>
		                	</div>
			    					</div>
			    				</div> */}
			    			</div>
			    		</div>
	    			</div>
	    			<div class="col-md-6 col-lg-3 ftco-animate">
	    				<div class="model-entry">
			    			<div class="model-img" >
							<img src={img5} alt=""/>
			    				{/* <div class="name text-center">
			    					<h3><a href="model-single.html">Nicole</a></h3>
			    				</div> */}
			    				{/* <div class="text text-center">
			    					<h3><a href="model-single.html">Nicole<br/><span>Wall</span></a></h3>
			    					<div class="d-flex models-info">
			    						<div class="box">
		                		<p>Height</p>
		                		<span>185</span>
		                	</div>
		                	<div class="box">
		                		<p>Bust</p>
		                		<span>79</span>
		                	</div>
		                	<div class="box">
		                		<p>Waist</p>
		                		<span>40</span>
		                	</div>
		                	<div class="box">
		                		<p>Hips</p>
		                		<span>87</span>
		                	</div>
		                	<div class="box">
		                		<p>Shoe</p>
		                		<span>40</span>
		                	</div>
			    					</div>
			    				</div> */}
			    			</div>
			    		</div>
	    			</div>
	    			<div class="col-md-6 col-lg-3 ftco-animate">
	    				<div class="model-entry">
			    			<div class="model-img">
							<img src={img6} alt=""/>
			    				{/* <div class="name text-center">
			    					<h3><a href="model-single.html">Jannah</a></h3>
			    				</div> */}
			    				{/* <div class="text text-center">
			    					<h3><a href="model-single.html">Jannah<br/><span>Tan</span></a></h3>
			    					<div class="d-flex models-info">
			    						<div class="box">
		                		<p>Height</p>
		                		<span>185</span>
		                	</div>
		                	<div class="box">
		                		<p>Bust</p>
		                		<span>79</span>
		                	</div>
		                	<div class="box">
		                		<p>Waist</p>
		                		<span>40</span>
		                	</div>
		                	<div class="box">
		                		<p>Hips</p>
		                		<span>87</span>
		                	</div>
		                	<div class="box">
		                		<p>Shoe</p>
		                		<span>40</span>
		                	</div>
			    					</div>
			    				</div> */}
			    			</div>
			    		</div>
	    			</div>
	    			<div class="col-md-6 col-lg-3 ftco-animate">
	    				<div class="model-entry">
			    			<div class="model-img" >
							<img src={img7} alt=""/>
			    				{/* <div class="name text-center">
			    					<h3><a href="model-single.html">Mariel</a></h3>
			    				</div>
			    				<div class="text text-center">
			    					<h3><a href="model-single.html">Mariel<br/><span>Rivera</span></a></h3>
			    					<div class="d-flex models-info">
			    						<div class="box">
		                		<p>Height</p>
		                		<span>185</span>
		                	</div>
		                	<div class="box">
		                		<p>Bust</p>
		                		<span>79</span>
		                	</div>
		                	<div class="box">
		                		<p>Waist</p>
		                		<span>40</span>
		                	</div>
		                	<div class="box">
		                		<p>Hips</p>
		                		<span>87</span>
		                	</div>
		                	<div class="box">
		                		<p>Shoe</p>
		                		<span>40</span>
		                	</div>
			    					</div>
			    				</div> */}
			    			</div>
			    		</div>
	    			</div>
	    			<div class="col-md-6 col-lg-3 ftco-animate">
	    				<div class="model-entry">
			    			<div class="model-img">
								<img src={img8} alt=""/>
			    				{/* <div class="name text-center">
			    					<h3><a href="model-single.html">Maria</a></h3>
			    				</div> */}
			    				{/* <div class="text text-center">
			    					<h3><a href="model-single.html">Maria<br/><span>Corazon</span></a></h3>
			    					<div class="d-flex models-info">
			    						<div class="box">
		                		<p>Height</p>
		                		<span>185</span>
		                	</div>
		                	<div class="box">
		                		<p>Bust</p>
		                		<span>79</span>
		                	</div>
		                	<div class="box">
		                		<p>Waist</p>
		                		<span>40</span>
		                	</div>
		                	<div class="box">
		                		<p>Hips</p>
		                		<span>87</span>
		                	</div>
		                	<div class="box">
		                		<p>Shoe</p>
		                		<span>40</span>
		                	</div>
			    					</div>
			    				</div> */}
			    			</div>
			    		</div>
	    			</div>
	    		</div>
	    		<div class="row mt-5">
	          <div class="col text-center">
	            <div class="block-27">
	              <ul>
	                <li><a href="#">&lt;</a></li>
	                <li class="active"><span>1</span></li>
	                <li><a href="#">2</a></li>
	                <li><a href="#">3</a></li>
	                <li><a href="#">4</a></li>
	                <li><a href="#">5</a></li>
	                <li><a href="#">&gt;</a></li>
	              </ul>
	            </div>
	          </div>
	        </div>
	    	</div>
	    </section>
      
      <Footer/>

    
      </div>

	    {/* <!-- Modal --> */}
	    <div class="modal fade" id="modalRequest" tabindex="-1" role="dialog" aria-labelledby="modalRequestLabel" aria-hidden="true">
	      <div class="modal-dialog" role="document">
	        <div class="modal-content">
	          <div class="modal-header">
	            <h5 class="modal-title" id="modalRequestLabel">Request a Quote</h5>
	            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
	              <span aria-hidden="true">&times;</span>
	            </button>
	          </div>
	          <div class="modal-body">
	            <form action="#">
	              <div class="form-group">
	                <label for="appointment_name" class="text-black">Full Name</label>
	                <input type="text" class="form-control" id="appointment_name"/>
	              </div>
	              <div class="form-group">
	                <label for="appointment_email" class="text-black">Email</label>
	                <input type="text" class="form-control" id="appointment_email"/>
	              </div>
	              <div class="row">
	                <div class="col-md-6">
	                  <div class="form-group">
	                    <label for="appointment_date" class="text-black">Date</label>
	                    <input type="text" class="form-control" id="appointment_date"/>
	                  </div>    
	                </div>
	                <div class="col-md-6">
	                  <div class="form-group">
	                    <label for="appointment_time" class="text-black">Time</label>
	                    <input type="text" class="form-control" id="appointment_time"/>
	                  </div>
	                </div>
	              </div>
	              

	              <div class="form-group">
	                <label for="appointment_message" class="text-black">Message</label>
	                <textarea name="" id="appointment_message" class="form-control" cols="30" rows="10"></textarea>
	              </div>
	              <div class="form-group">
	                <input type="submit" value="Send Message" class="btn btn-primary"/>
	              </div>
	            </form>
	          </div>
	          
	        </div>
	      </div>
	    </div>
    </div>
  )
}

export default Designs
