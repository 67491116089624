import React from 'react'
import { Link } from 'react-router-dom';
import "./style.css";
const Navbar = () => {
  return (
    <div>
      <nav id="colorlib-main-nav" role="navigation">
        <a href="/" class="js-colorlib-nav-toggle colorlib-nav-toggle active">
          <i></i>
        </a>
        <div class="js-fullheight colorlib-table">
          <div class="img "></div>
          <div class="colorlib-table-cell js-fullheight">
            <div class="row no-gutters">
              <div class="col-md-12 bfg text-center">
                <h1 class="mb-md-4 ">
                  <a href="/" class="logo">
                    Tihut
                    <br />
                    <span>Cultural Design</span>
                  </a>
                </h1>
                <ul class="">
                  <li class="active">
                    <a href="/">
                      <span>Home</span>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <span>About</span>
                    </a>
                  </li>
                  <li>
                    <a href="/designs">
                      <span>Designs</span>
                    </a>
                  </li>
                  <li>
                    <a href="/blog">
                      <span>Blog</span>
                    </a>
                  </li>
                  <li>
                    <a href='/contact'>
                      <span>Contact</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <header>
        <div class="container">
          <div class="colorlib-navbar-brand">
            <a class="colorlib-logo" href="/">
              Tihut
              <br />
              <span>Cultural Design</span>
            </a>
          </div>
          <a href="/" class="js-colorlib-nav-toggle colorlib-nav-toggle">
            <i></i>
          </a>
        </div>
      </header>
    </div>
  );
}

export default Navbar
