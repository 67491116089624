import React from 'react'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'
import "./../component/style.css";
const Blog = () => {
  return (
    <div class="page">
      <Navbar />
      <div id="colorlib-page">
        <section class="hero-wrap imgh1">
          <div class="overlay"></div>
          <div class="container">
            <div
              class="row no-gutters text align-items-end justify-content-center"
              data-scrollax-parent="true"
            >
              <div class="col-md-8 ftco-animate text-center">
                <p class="breadcrumbs">
                  <span class="mr-2">
                    <a href="/">Home</a>
                  </span>{" "}
                  <span>Blog</span>
                </p>
                <h1 class="mb-5 bread">Our Blog</h1>
              </div>
            </div>
          </div>
        </section>

        <section class="ftco-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 mb-5 heading-section text-center ftco-animate">
                <span class="subheading">Blog</span>
                <h2 class="mb-4">Recent Blog</h2>
                <p class="mb-5">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. In
                  veniam, ut et natus sit obcaecati, beatae possimus, quos
                  doloremque laboriosam nostrum neque praesentium quisquam nihil
                  odit tenetur quas fugiat deleniti.{" "}
                </p>
              </div>
            </div>
            <div class="row d-flex">
              <div class="col-md-4 d-flex ftco-animate">
                <div class="blog-entry bg-dark align-self-stretch">
                  <a href="blog-single.html" class="block-20 img1"></a>
                  <div class="text p-4 d-block">
                    <div class="meta mb-3">
                      <div>
                        <a href="#">May 17, 2019</a>
                      </div>
                    </div>
                    <h3 class="heading mt-3">
                      <a href="#">Lorem, ipsum dolor sit</a>
                    </h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary">
                        Read more
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-flex ftco-animate">
                <div class="blog-entry bg-dark align-self-stretch">
                  <a href="blog-single.html" class="block-20 img2"></a>
                  <div class="text p-4 d-block">
                    <div class="meta mb-3">
                      <div>
                        <a href="#">May 17, 2019</a>
                      </div>
                    </div>
                    <h3 class="heading mt-3">
                      <a href="#">Lorem, ipsum dolor sit</a>
                    </h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary">
                        Read more
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-flex ftco-animate">
                <div class="blog-entry bg-dark align-self-stretch">
                  <a href="blog-single.html" class="block-20 img3"></a>
                  <div class="text p-4 d-block">
                    <div class="meta mb-3">
                      <div>
                        <a href="#">May 17, 2019</a>
                      </div>
                    </div>
                    <h3 class="heading mt-3">
                      <a href="#">Lorem, ipsum dolor sit</a>
                    </h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary">
                        Read more
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-flex ftco-animate">
                <div class="blog-entry bg-dark align-self-stretch">
                  <a href="blog-single.html" class="block-20 img4"></a>
                  <div class="text p-4 d-block">
                    <div class="meta mb-3">
                      <div>
                        <a href="#">May 17, 2019</a>
                      </div>
                    </div>
                    <h3 class="heading mt-3">
                      <a href="#">Lorem, ipsum dolor sit</a>
                    </h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary">
                        Read more
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-flex ftco-animate">
                <div class="blog-entry bg-dark align-self-stretch">
                  <a href="blog-single.html" class="block-20 img5"></a>
                  <div class="text p-4 d-block">
                    <div class="meta mb-3">
                      <div>
                        <a href="#">May 17, 2019</a>
                      </div>
                    </div>
                    <h3 class="heading mt-3">
                      <a href="#">Lorem, ipsum dolor sit</a>
                    </h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary">
                        Read more
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-flex ftco-animate">
                <div class="blog-entry bg-dark align-self-stretch">
                  <a href="blog-single.html" class="block-20 img6"></a>
                  <div class="text p-4 d-block">
                    <div class="meta mb-3">
                      <div>
                        <a href="#">May 17, 2019</a>
                      </div>
                    </div>
                    <h3 class="heading mt-3">
                      <a href="#">Lorem, ipsum dolor sit</a>
                    </h3>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary">
                        Read more
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col text-center">
                <div class="block-27">
                  <ul>
                    <li>
                      <a href="#">&lt;</a>
                    </li>
                    <li class="active">
                      <span>1</span>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li>
                      <a href="#">4</a>
                    </li>
                    <li>
                      <a href="#">5</a>
                    </li>
                    <li>
                      <a href="#">&gt;</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="modalRequest"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalRequestLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalRequestLabel">
                Request a Quote
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form action="#">
                <div class="form-group">
                  <label for="appointment_name" class="text-black">
                    Full Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="appointment_name"
                  />
                </div>
                <div class="form-group">
                  <label for="appointment_email" class="text-black">
                    Email
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="appointment_email"
                  />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="appointment_date" class="text-black">
                        Date
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="appointment_date"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="appointment_time" class="text-black">
                        Time
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="appointment_time"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="appointment_message" class="text-black">
                    Message
                  </label>
                  <textarea
                    name=""
                    id="appointment_message"
                    class="form-control"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    class="btn btn-primary"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog
